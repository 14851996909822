import { IconButton, TableCell, TableRow, Tooltip, Dialog,Checkbox } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { withRouter,Link } from "react-router-dom";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import AdTaskForm from "./AdTaskForm";
import { regexConstants } from "../../../../constants/regEx";
import {dayMonthDateFormat } from "../../../../constants";
import { fillAdEditFormApi } from "../apiService";
import ConfirmDialog from "./ConfirmDialog";
import ShowAttachments from "../../../../shared_elements/components/ShowAttachments";
import moment from "moment";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import RevisionHistory from "../../../../shared_elements/components/RevisionHistory";
import { permissionCheckFnforCamo, checkApiStatus,Comma_format } from "../../../../utils";
import { globalPutService, globalDeleteService } from "../../../../utils/globalApiServices";
import { getLocalStorageInfo } from "../../../../utils";
import config from "../../../../config"
const AdList = ({match, item, handleDeleteRow, updateFormData, props, fileUploadData, currentRecordPerPage, downloadAllApi, headerTsn, headerCsn, uuid, getResponseBack, last_used_folder_uuid, last_used_folder_uuid_link_document, checkbox_last_used_folder_uuid, checkbox_last_used_folder_name, assetsDetail,bulkOperation ,toggleBulkOps,basicDetails}) => {
	const [open, setOpen] = useState(false);
	const [openEditForm, setEditForm] = useState(false);
	const [editFormId, setEditFormId] = useState(null);
	const [editFormData, setEditFormData] = useState(null);
	const [formTitle, setFormTitle] = useState("");
	const [attachments, setAttachments] = useState(null);
	const [showFullDescription, setShowFullDescription] = useState({});
	const [openGetAttachmentsDialogue, setAttachmentsDialogue] = useState(false);
	const [AllAttachmentsforDialogue, setAllAttachmentsforDialogue] =
		useState(null);
	const [moduleId, setmoduleId] = useState(null);
	const [lessMore, setLessMore] = useState({});
	const handleClick = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};
	const params = new URLSearchParams(props.location?.search);
	const queryValue = params.get('record_id');
console.log("match---",match?.params?.assetType === '2')
	const closeAddForm = () => {
		setEditForm(false);
		if(queryValue){
			const newURL = `${window.location?.origin + window.location?.pathname}`
			window.history.replaceState({ ...window.history.state, as: newURL, url: newURL }, '', newURL)
		}
	};

	const handleEditRow = () => {
			fillAdEditFormApi(item, props).then((response) => {
				setEditFormData(response.data.data);
				setEditFormId(response.data.data.id);
				setAttachments(response.data.data.attachments);
				setFormTitle("Update Airworthiness Directives (ADs)");
				setEditForm(true);
			});
	};
	useEffect(()=>{
		if(item?.id === parseInt(queryValue)){
			handleEditRow(parseInt(queryValue))
		}
	},[])

	const downAllAttach = () => {
		fillAdEditFormApi(item, props).then((response) => {
			setmoduleId(response.data.data.id);
		});
		getAttachements();
	};
	const getAttachements = () => {
		const matchingItem = assetsDetail?.list?.find(items => items?.id === item.id);
		if (!matchingItem) return [];
		const { checklist, attachments } = matchingItem || {};
		if (checklist && attachments || checklist || attachments) {
			const { files, folders } = checklist || {}; // Add null check here
			if (attachments?.length > 0 && files?.length > 0 && folders?.length > 0) {
				getAttachments([...attachments, ...files, ...folders])
			} else if (files?.length > 0 && folders?.length > 0) {
				getAttachments([...folders, ...files])
			} else if (attachments?.length > 0 && files?.length > 0) {
				getAttachments([...attachments, ...files])
			} else if (attachments?.length > 0 && folders?.length > 0) {
				getAttachments([...attachments, ...folders])
			} else if (attachments?.length > 0) {
				getAttachments([...attachments])
			} else if (folders?.length > 0) {
				getAttachments([...folders])
			} else if (files?.length > 0) {
				getAttachments([...files])
			}
		}
		return [];

	};

	const openAttachment = (file) => {
		var decodedURL = decodeURIComponent(file);
		window.open(decodedURL, "_blank");
	};

	const formatDate = (date) => {
		return (moment(date).format(dayMonthDateFormat));
	};
	const toggleDescription = (id) => {
		setShowFullDescription({
			...showFullDescription,
			[id]: !showFullDescription[id],
		});
	};

	const getAttachments = (attachments) => {
		setAllAttachmentsforDialogue(attachments);
		setAttachmentsDialogue(true);
	};
	const handleCloseAttachmentDialogue = () => {
		setAttachmentsDialogue(false);
	};

	const removeAttachment = (item, checklist, fileId, uuid) => {
		if (uuid) {
			let payload = {};
			payload.delete = true;
			payload.folder_ids = checklist.filter(items => items.type === 'folder').map(file => file.id).filter((id) => id !== fileId).toString()
			payload.file_ids = checklist.filter(items => items.type === 'file').map(file => file.id).filter((id) => id !== fileId).toString()
			globalPutService(`camo/aird/${item?.id}/document-checklists/`, payload)
				.then((response) => {
					if (checkApiStatus(response)) {
						fillAdEditFormApi(item, props).then((response) => {
							setEditFormData(response.data.data);
							const matchingItem = response.data.data;
							if (!matchingItem) return [];
							const { checklist, attachments } = matchingItem || {};
							if (checklist && attachments || checklist || attachments) {
								if (item?.attachments?.length > 0 && item.checklist?.files?.length > 0 && item?.checklist?.folders?.length > 0) {
									getAttachments([...response.data.data.attachments, ...response.data?.data?.checklist?.files, ...response.data.data.checklist.folders])
								}else if (item?.attachments?.length > 0 && item.checklist?.folders?.length > 0) {
									getAttachments([...response.data.data.attachments, ...response.data.data.checklist.folders])
								} else if (item?.attachments?.length > 0 && item.checklist?.files?.length > 0) {
									getAttachments([...response.data.data.attachments, ...response?.data?.data?.checklist?.files])
								} else if (item.checklist?.files?.length > 0 && item.checklist?.folders?.length > 0) {
									getAttachments([...response?.data?.data?.checklist?.files, ...response.data.data.checklist.folders])
								} else if (item?.attachments?.length > 0) {
									getAttachments([...response.data.data.attachments])
								} else if (item.checklist?.folders?.length > 0) {
									getAttachments([...response.data.data.checklist.folders])
								} else if (item.checklist?.files?.length > 0) {
									getAttachments([...response?.data?.data?.checklist?.files])
								}
								getResponseBack()
							}

							return [];
						});
					}
				})
		} else {
			globalDeleteService(`camo/attachment/${fileId}/delete/`).then((response) => {
				if (response.status === 500) {
					props.enqueueSnackbar("Something went wrong.", {
						variant: "error",
						anchorOrigin: { vertical: "top", horizontal: "right" },
					});
				} else {
					if (response) {
						fillAdEditFormApi(item, props).then((response) => {
							setEditFormData(response.data.data);
							const matchingItem = response.data.data;
							if (!matchingItem) return [];
							const { checklist, attachments } = matchingItem || {};
							if (checklist && attachments || checklist || attachments) {
								if (item?.attachments?.length > 0 && item.checklist?.files?.length > 0 && item?.checklist?.folders?.length > 0) {
									getAttachments([...response.data.data.attachments, ...response.data?.data?.checklist?.files, ...response.data.data.checklist.folders])
								}else if (item?.attachments?.length > 0 && item.checklist?.folders?.length > 0) {
									getAttachments([...response.data.data.attachments, ...response.data.data.checklist.folders])
								} else if (item?.attachments?.length > 0 && item.checklist?.files?.length > 0) {
									getAttachments([...response.data.data.attachments, ...response?.data?.data?.checklist?.files])
								} else if (item.checklist?.files?.length > 0 && item.checklist?.folders?.length > 0) {
									getAttachments([...response?.data?.data?.checklist?.files, ...response.data.data.checklist.folders])
								} else if (item?.attachments?.length > 0) {
									getAttachments([...response.data.data.attachments])
								} else if (item.checklist?.folders?.length > 0) {
									getAttachments([...response.data.data.checklist.folders])
								} else if (item.checklist?.files?.length > 0) {
									getAttachments([...response?.data?.data?.checklist?.files])
								}
								getResponseBack()
							}

							return [];
						});
					}
				}
			});
		}
	};
	let timezone = moment().format()
	const timestamp = Math.floor(Date.now() / 1000);
	const adurl = `audit/camo/adstatus/?object_id=${item.id}&action=0,1&timezone=${timezone}&timestamp=${timestamp}`
	const smbcInstance = getLocalStorageInfo()?.defaultLessor?.id === (config?.env?.key === "api" ? 465 : 532)

	function getAttachmentCount(item) {
		const { attachments, checklist } = item;
		const { files, folders } = checklist || {};

		const attachmentCount = attachments?.length || 0;
		const fileCount = files?.length || 0;
		const folderCount = folders?.length || 0;

		if (attachmentCount && fileCount && folderCount) {
			return attachmentCount + fileCount + folderCount;
		} else if (fileCount && folderCount) {
			return fileCount + folderCount;
		} else if (attachmentCount && folderCount) {
			return attachmentCount + folderCount;
		} else if (attachmentCount && fileCount) {
			return attachmentCount + fileCount;
		} else {
			return folderCount || fileCount || attachmentCount || '--';
		}
	}
	const attachmentCount = getAttachmentCount(item);
	const selectedItem = bulkOperation.ids.find(data => data.id === item?.id);

    const nextDueFormat = (item) => {
        if (item.ad_compliance_status === "NOT APPLICABLE" || item.ad_compliance_status === "N/A") {
            return ("N/A")
        } else if (item.remaining.due_at && item.remaining.due_at_type === 'd') {
            return (moment(item.remaining.due_at).isValid() ? moment(item.remaining.due_at).format(dayMonthDateFormat) : '--')
        } else if (item.remaining.due_at && item.remaining.due_at_type === 's') {
            return item.remaining.due_at || '--'
        } else if ((item.ad_compliance_status !== "NOT APPLICABLE" || item.ad_compliance_status !== "N/A") && !item.remaining.due_at  ) {
            return item?.next_due_note
        }else {
            return moment(item.due_date).isValid() ? moment(item.due_date).format(dayMonthDateFormat) : '--'
        }
    }
	const toggleLessMore = (lessMr) => {
		setLessMore({
			...lessMore,
			[lessMr]: !lessMore[lessMr]
		});
	}

	const lessMoreFn = (name, lessMr) => {
		return <>
			{name && name?.length > 35 ? (
				<>
					{lessMore[lessMr] ?
						(<> {name} <span style={{ color: "#0e7fe1" }} onClick={(e) => { e.preventDefault(); toggleLessMore(lessMr); }}  > ..less </span> </>)
						:
						(<>{name.substring(0, 35)} <span style={{ color: "#0e7fe1" }} onClick={(e) => { e.preventDefault(); toggleLessMore(lessMr); }}  > ..more </span> </>)
					}
				</>
			) : name || '--'
			}
		</>
	}

	return (
		<>
			<TableRow
				hover
				tabIndex={-1}
				style={{ cursor: "pointer" }}
				className={item.remainingValues_D < 0 || item.remainingValues_D == null ? "red-row" : "none"}
			>
				 {window?.location?.search.includes('archived-assets') ? null :  <TableCell width="30">
                    <Checkbox
                        name={item.id}
                        color="primary"
                        size="small"
                        onChange={(e) => toggleBulkOps(e.target.checked)}
                        checked={selectedItem ? true : false}
                    />
                </TableCell>}
				<TableCell> {window?.location?.search.includes('archived-assets') ? null : (
					<Tooltip title="Edit">
						<IconButton className="edit-icon" onClick={(e) => { handleEditRow(e); }}>
							<CreateOutlinedIcon />
						</IconButton>
					</Tooltip>
				)}
					{window?.location?.search.includes('archived-assets') ? null : (
						<Tooltip title="Delete" arrow>
							<IconButton className="delete-icon" onClick={handleClick}>
								<DeleteOutlinedIcon />
							</IconButton>
						</Tooltip>
					)}
					<RevisionHistory buttonType={true} url={adurl} />
				</TableCell>
				<TableCell>{smbcInstance ? item.faa_ad : item?.ata || "--"}</TableCell>
				<TableCell>{smbcInstance ? item.faa_superseded : item.ad_no || "--"} </TableCell>
				{smbcInstance ? <TableCell>{item.easa_ad}</TableCell> : null}
				<TableCell>{smbcInstance ? item.easa_superseded : item.issuing_authority || "--"} </TableCell>
				<TableCell style={{ minWidth: "200px", textAlign: "left" }}>
					{item.description && item.description.length > 35 ? (
						<div>
							{showFullDescription[item.id] ? (
								<span> {item.description}
									<a href="#" style={{ color: "#0e7fe1" }} onClick={(e) => { e.preventDefault(); toggleDescription(item.id); }}  >..less </a>
								</span>
							) : (
								<span> {item.description.substring(0, 35)}
									<a href="#" style={{ color: "#0e7fe1" }} onClick={(e) => { e.preventDefault(); toggleDescription(item.id); }}  > ..more </a>
								</span>
							)}
						</div>
					) : item.description && item.description.length <= 35 ? (
						<span>{item.description}</span>
					) : (
						"--"
					)}
				</TableCell>
				{smbcInstance ? <TableCell style={{ minWidth: "200px", textAlign: "left" }}>{lessMoreFn(item?.related_document, "related_document")}</TableCell> : null}
				<TableCell style={{ minWidth: "200px", textAlign: "left" }}>{lessMoreFn(match?.params?.assetType === '2' ? item?.applicability?.map((item) => item?.name ? item?.name + ',' : '') : item?.applicability, "applicability") || '--'}</TableCell>
				<TableCell style={{ minWidth: "200px", textAlign: "left" }}>{lessMoreFn(item?.effectivity, "effectivity")}</TableCell>
				{smbcInstance ? <TableCell>{item?.faa_effective_date ?  formatDate(item.faa_effective_date) : "--"}</TableCell> : null}
				{smbcInstance ? <TableCell>{item?.easa_effective_date ? formatDate(item.easa_effective_date) : "--"}</TableCell> : null}
				{smbcInstance ? null : <TableCell>{item.ad_type ? item.ad_type : "--"}</TableCell>}
				{smbcInstance ? null : <TableCell>{item.effective_date ? formatDate(item.effective_date) : "--"}  </TableCell>}
				{smbcInstance ? null : <TableCell>{item.dimension_type ? item.dimension_type : "--"}  </TableCell>}
				<TableCell>{item.ad_compliance_status ? item.ad_compliance_status : "--"} </TableCell>
				{smbcInstance ? null : <TableCell>{item.ad_compliance_tsn ? regexConstants.alphanumespcespclchar.test(item.ad_compliance_tsn) ? item.ad_compliance_tsn : Comma_format(item.ad_compliance_tsn) : "--"} </TableCell>}
				{smbcInstance ? null : <TableCell>{item.ad_compliance_csn ? regexConstants.alphanumespcespclchar.test(item.ad_compliance_csn) ? item.ad_compliance_csn : Comma_format(item.ad_compliance_csn) : "--"} </TableCell>}
				{smbcInstance ? null : <TableCell>{item.ad_compliance_date ? formatDate(item.ad_compliance_date) : "--"}</TableCell>}
				<TableCell>{nextDueFormat(item)}</TableCell>
				{/* {smbcInstance ? null : <TableCell>{item.sb_number ? item.sb_number : "--"}</TableCell>} */}
				<TableCell>{item?.sb_number?.length > 0 && item?.sb_number?.map(items=>(<Link to={`/${props.match.params.asset}/${props?.match.params.assetType}/sb/list?query=${items?.name}`}  target="_self">{items?.name + ","}</Link>)) || '--'}</TableCell>
				{smbcInstance ? <TableCell>{item?.reference || "--"}</TableCell> : null}
				{smbcInstance ? <TableCell>{lessMoreFn(item?.remark, "remark")}</TableCell> : null}
				<TableCell>
					{attachmentCount ? (
						<>
							<Tooltip title="View Attachments">
								<span className="center" onClick={() => downAllAttach()} >
									<AttachFileIcon className="attach-icon" />
									<a href="#" style={{ color: "#0e7fe1" }}>{attachmentCount}</a>
								</span>
							</Tooltip>
						</>
					)
						: (
							"--"
						)}
				</TableCell>
			</TableRow>
			<Dialog open={open} onClose={handleClose} id="htDialog">
				<ConfirmDialog
					handleClose={() => handleClose()}
					handleDeleteRow={handleDeleteRow}
				/>
			</Dialog>
			<Dialog
				position="relative"
				maxWidth="lg"
				open={openEditForm}
				// onClose={closeAddForm}
			>
				<AdTaskForm
					formTitle={formTitle}
					closeAddForm={() => closeAddForm()}
					addForm={() => setEditForm(true)}
					props={props}
					editFormId={editFormId}
					editFormData={editFormData}
					attachments={attachments}
					item={item}
					uuid={uuid}
					basicDetails={basicDetails}
					headerTsn={headerTsn}
					headerCsn={headerCsn}
					updateFormData={updateFormData}
					fileUploadData={fileUploadData}
					currentRecordPerPage={currentRecordPerPage}
					last_used_folder_uuid={last_used_folder_uuid}
					last_used_folder_uuid_link_document={last_used_folder_uuid_link_document}
					checkbox_last_used_folder_uuid={checkbox_last_used_folder_uuid}
					checkbox_last_used_folder_name={checkbox_last_used_folder_name}
					getResponseBack={() => getResponseBack()}
				/>
			</Dialog>
			<Dialog
				open={openGetAttachmentsDialogue}
				onClose={handleCloseAttachmentDialogue}
				id="htDialog"
			>
				<ShowAttachments
					handleClose={() => handleCloseAttachmentDialogue()}
					handleShowAttachments={AllAttachmentsforDialogue}
					openAttachment={openAttachment}
					files={{ title: "AD", extension: "zip", key: "" }}
					downloadAllApi={downloadAllApi}
					moduleId={moduleId}
					item={item}
					basicDetails={basicDetails}
					removeAttachment={removeAttachment}
				/>
			</Dialog>
		</>
	);
};
export default withRouter(AdList);

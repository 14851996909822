import React, { Component, Fragment } from "react"
import { Button, DialogTitle, DialogContent, TextField, DialogActions, Grid, Dialog, TextareaAutosize, Tooltip } from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"
import Autocomplete from "@material-ui/lab/Autocomplete"
import GetAppIcon from '@material-ui/icons/GetApp';
import AttachmentIcon from '@material-ui/icons/Attachment';
import LinkIcon from '@material-ui/icons/Link';
import InfoIcon from '@material-ui/icons/Info';
import { formValues } from "../containers/OpTaskformData"
import { KeyboardDatePicker, MuiPickersUtilsProvider, } from "@material-ui/pickers"
import moment from "moment"
import axios from 'axios';
import MomentUtils from "@date-io/moment"
import { backendDateFormat, dayMonthDateFormat } from "../../../../constants"
import { addOpFormApi, deleteOpAttachment, getOperatorList, allDropDownList, getAllOppThrust } from "../apiServices"
import {getLocalStorageInfo, checkApiStatus,format_Commas,validationMess } from "../../../../utils"
import { globalPutService } from "../../../../utils/globalApiServices"
import LinkFiles from "../../../../shared_elements/components/LinkFiles"
import UploadFile from "../../../../shared_elements/components/UploadFile"
import { regexConstants } from "../../../../constants/regEx";
import { assetType, imgStoragePath, specificKey } from "../../../../constants";
import config from "../../../../config";

class OpTaskForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            error: {},
            data: formValues,
            attachments: [],
            newUploadedAttachments: [],
            btnStatus: "",
            open: false,
            deleteAttachmentId: 0,
            showSuccessUpload: false,
            linkFile: false,
            uploadFile: false,
            operatorList: [],
            positionDropDownList: [],
            operatingThrust: [],
        }
        this.addOpFormApi = addOpFormApi.bind(this)
        this.getOperatorList = getOperatorList.bind(this)
        this.allDropDownList = allDropDownList.bind(this)
        this.getAllOppThrust = getAllOppThrust.bind(this)
    }

    componentDidMount = () => {
        if (this.props.editFormId) {
            this.fillEditData()
        }
        this.getOperatorList()
        this.allDropDownList()
        this.getAllOppThrust(this.props.props.match.params, this.props.engineType?.engine_type)
    }
    handleOpenFile = () => {
        this.setState({ linkFile: true })
    };

    handleCloseFile = () => {
        this.setState({ linkFile: false })
    };
    handleOpenUploadFile = () => {
        this.setState({ uploadFile: true })
    };
    handleUploadFileCloseFile = () => {
        this.setState({ uploadFile: false })
    };

    onFieldChange = (keyParam, value, removeComma) => {
        if (removeComma) {
            this.setState((prevState) => ({
                ...prevState,
                data: {
                    ...prevState.data,
                    [keyParam]: value ? value.replace(",", '') : value,
                },
            }))
        } else {
            this.setState((prevState) => ({
                ...prevState,
                data: {
                    ...prevState.data,
                    [keyParam]: value === "" ? null : value,
                },
            }))
        }
    }

    onRestErrorKey = (keyParam) => {
        this.setState((prevState) => ({
            ...prevState,
            error: {
                ...prevState.error,
                [keyParam]: "",
            },
        }))
    }

    handleSubmit = (e) => {
        e.preventDefault()
        const { data } = this.state
        const props = this.props.props
        let payload = Object.assign({}, data)
        payload = {
            ...payload,
            asset_id: props.match.params.asset,
            asset_type_id: props.match.params.assetType,
            fitment_date: payload.fitment_date ? payload.fitment_date : null,
            removal_engine_tsn: payload.removal_engine_tsn ? payload.removal_engine_tsn : null,
            removal_engine_csn: payload.removal_engine_csn ? payload.removal_engine_csn : null,
            fitment_engine_csn: payload.fitment_engine_csn ? payload.fitment_engine_csn : null,
            fitment_engine_tsn: payload.fitment_engine_tsn ? payload.fitment_engine_tsn : null,
            removal_cslsv: payload.removal_cslsv ? payload.removal_cslsv : null,
            removal_tslsv: payload.removal_tslsv ? payload.removal_tslsv : null,
        }
        if (parseFloat(payload?.fitment_engine_tsn) > parseFloat(payload?.removal_engine_tsn)) {
            return (this.props.props.enqueueSnackbar("Fitment Engine TSN should be less than Removal Engine TSN.", { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } }))

        } else if (parseInt(payload?.fitment_engine_csn) > parseInt(payload?.removal_engine_csn)) {
            return (this.props.props.enqueueSnackbar("Fitment Engine CSN should be less than Removal Engine CSN", { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } }))

        } else {
            addOpFormApi(payload, props, this.props.addForm).then(async (response) => {
                const uploadId = response.data.data.id
                if (uploadId) {
                    await this.uploadFileTest(uploadId, props, false)
                }
                if (uploadId && this.state.btnStatus !== "addAnother") {
                    this.props.closeAddForm()
                } else {
                    return false
                }
            })
        }


    }
    fillEditData = () => {
        const updateId = this.props.editFormId
        this.setState({ attachments: this.props.attachments })
        if (updateId === undefined || updateId === null) {
            this.setState({
                data: {},
            })
        } else {
            this.setState({
                data: this.props.editFormData,
            })
        }
    }
    isDateValid(dateStr) {
        return !isNaN(new Date(dateStr));
    }


    updateData = async (e) => {
        e.preventDefault()
        const { data } = this.state
        let payload = Object.assign({}, data)
        const props = this.props.props
        payload = {
            ...payload,
            asset_type_id: props.match.params.assetType,
            fitment_date: payload.fitment_date ? payload.fitment_date : null,
            removal_engine_tsn: payload.removal_engine_tsn ? payload.removal_engine_tsn : null,
            removal_engine_csn: payload.removal_engine_csn ? payload.removal_engine_csn : null,
            fitment_engine_csn: payload.fitment_engine_csn ? payload.fitment_engine_csn : null,
            fitment_engine_tsn: payload.fitment_engine_tsn ? payload.fitment_engine_tsn : null,
            removal_cslsv: payload.removal_cslsv ? payload.removal_cslsv : null,
            removal_tslsv: payload.removal_tslsv ? payload.removal_tslsv : null,
        }
        if (parseFloat(payload?.fitment_engine_tsn) > parseFloat(payload?.removal_engine_tsn)) {
            return (this.props.props.enqueueSnackbar("Fitment Engine TSN should be less than Removal Engine TSN.", { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } }))

        } else if (parseInt(payload?.fitment_engine_csn) > parseInt(payload?.removal_engine_csn)) {
            return (this.props.props.enqueueSnackbar("Fitment Engine CSN should be less than Removal Engine CSN", { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } }))

        } else {
            const updateId = this.props.editFormId
            if (updateId) {
                this.uploadFileTest(updateId, props, true)
            }
            this.props.updateFormData(updateId, payload, props, this.props.addForm, this.props.closeAddForm)
            // this.props.closeAddForm()

        }

    }
    uploadFileTest = async (uploadId, props, isUpdate) => {
        if (this.state.newUploadedAttachments.length > 0) {
            let newAttachmentObj = []
            let formdata = new FormData()
            const module_type_id = 13
            Object.keys(this.state.newUploadedAttachments).map((key, index) => {
                newAttachmentObj.push({
                    file: this.state.newUploadedAttachments[key],
                    file_name: this.state.newUploadedAttachments[key].name,
                    file_type: this.state.newUploadedAttachments[key].type,
                    module_type_id,
                    module_id: uploadId,
                    asset_id: this.props.props.match.params.asset,
                    asset_type_id: this.props.props.match.params.assetType

                })
                formdata.append(
                    "[" + key + "]file",
                    this.state.newUploadedAttachments[key]
                )
                formdata.append(
                    "[" + key + "]file_type",
                    this.state.newUploadedAttachments[key].type
                )
                formdata.append(
                    "[" + key + "]file_name",
                    this.state.newUploadedAttachments[key].name
                )
                formdata.append("[" + key + "]module_id", uploadId)
                formdata.append("[" + key + "]module_type_id", module_type_id)
                formdata.append("[" + key + "]asset_id", this.props.props.match.params.asset)
                formdata.append("[" + key + "]asset_type_id", this.props.props.match.params.assetType)
            })
            this.props.fileUploadData(uploadId, formdata, props, true)
            if (this.state.btnStatus === "addAnother") {
                this.resetBothAttachmentArray()
            }
            if (!isUpdate) {
                this.onSubmitFileLink(uploadId)
                this.props.fileUploadData(uploadId, null, props, false)
            }
        } else {
            if (this.state.btnStatus === "addAnother") {
                this.setState((prevState) => ({
                    ...prevState,
                    data: formValues,
                    attachments: [],
                }))
                document.getElementById("asset-form").reset()
                this.props.fileUploadData(uploadId, null, props, false)
            } else {
                if (!isUpdate) {
                    this.onSubmitFileLink(uploadId)
                    this.props.fileUploadData(uploadId, null, props, false)
                }
            }
        }
    }

    resetBothAttachmentArray = () => {
        this.setState((prevState) => ({
            ...prevState,
            data: formValues,
            attachments: [],
            newUploadedAttachments: [],
            open: false,
            deleteAttachmentId: 0,
        }))
        document.getElementById("asset-form").reset()
    }

    onUpload = (files) => {
        const chosenFiles = Array.prototype.slice.call(files)
        for (let i = 0; i < chosenFiles.length; i++) {
            if (chosenFiles[i].size > 52428800) {
                this.props.props.enqueueSnackbar(
                    `${chosenFiles[i].name} Maximum file size should be 50MB.`,
                    {
                        variant: "error",
                        anchorOrigin: { vertical: "top", horizontal: "right" },
                    }
                )
                delete chosenFiles[i]
                continue
            } else {
                this.handleUploadFiles(chosenFiles)
                // document.querySelector(".MuiDialog-paper").scrollTop = 800;
                this.setState({
                    showSuccessUpload: true,
                })
                setTimeout(() => {
                    if (this.state.attachments) {
                        this.setState({
                            showSuccessUpload: false,
                        })
                    }
                }, 2500)
            }
        }
    }

    handleUploadFiles = (files) => {
        const uploaded = [...this.state.newUploadedAttachments]
        const existingAttachments = [...this.state.attachments]
        files.some((file) => {
            uploaded.push(file)
        })
        files.map((file) => {
            var objc = {
                id: 0,
                module_id: 0,
                module_type_id: 13,
                file_name: file.name,
                file_type: file.type,
                file: "",
                name: file.name,
                asset_id: this.props.props.match.params.asset,
                asset_type_id: this.props.props.match.params.assetType
            }
            existingAttachments.push(objc)
        })

        this.setState({
            newUploadedAttachments: uploaded,
            attachments: existingAttachments,
        })
    }

    handleClickAttachment = (e, id) => {
        this.setState({ open: true })
        this.setState({ deleteAttachmentId: id })
    }

    handleCloseAttachment = (e) => {
        this.setState({ open: false })
        this.setState({ deleteAttachmentId: 0 })
    }

    deleteAttachmentFile = async (id, file) => {
        if (id !== undefined && id != 0) {
            const props = this.props.props
            deleteOpAttachment(id, props)
            var array = [...this.state.attachments]
            array = array.filter((item) => item.id !== id)
            this.setState({ attachments: array, open: false, deleteAttachmentId: 0 })
        } else {
            this.onRemoveCamoFile(file)
        }
    }

    removeAttachment = (e, obj) => {
        var array = [...this.state.attachments]
        var newuploadarray = [...this.state.newUploadedAttachments]

        var filteredArray = []

        newuploadarray.map((file) => {
            if (file.name == obj.file_name && file.type == obj.file_type) {
            } else {
                filteredArray.push(file)
            }
        })
        // Set the state with the new filtered array
        array = array.filter((item) => item !== obj)
        this.setState({
            newUploadedAttachments: filteredArray,
            attachments: array,
        })

    }

    saveAndAddAnother = async (e) => {
        await this.setState({ btnStatus: "addAnother" })
        await this.handleSubmit(e)
    }

    addFormData = async (e) => {
        await this.setState({ btnStatus: "add" })
        this.handleSubmit(e)
    }

    getResponseBack = (file) => {
        const { data, } = this.state
        this.setState({
            data: {
                ...data,
                checklist: file,
            },
        });
    }
    listResponseBack = (file) => {
        if (this.props.actionType === "add" || 'edit') {
            return null
        } else {
            this.props.getResponseBack(file)
        }
    }
    downloadFile = (file) => {
        axios.get(decodeURIComponent(file.path), { timeout: 600000, responseType: 'blob' })
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', file.name);
                document.body.appendChild(link);
                link.click();
            })
    }
    onUnlinkCheckListFile = (checklist, fileId) => {
        const { data } = this.state;
        let payload = {};
        payload.delete = true;
        payload.folder_ids = checklist?.folders?.filter(items => items.type === 'folder').map(file => file.id).filter((id) => id !== fileId).join(',');
        payload.file_ids = checklist?.files?.filter(items => items.type === 'file').map(file => file.id).filter((id) => id !== fileId).join(',');
        globalPutService(`camo/ope_hist/${this.props.editFormId}/document-checklists/`, payload)
            .then((response) => {
                if (checkApiStatus(response)) {
                    const updatedChecklist = { ...checklist };
                    updatedChecklist.files = updatedChecklist.files.filter((file) => file.id !== fileId);
                    updatedChecklist.folders = updatedChecklist?.folders.filter((file) => file.id !== fileId);
                    this.setState({
                        data: {
                            ...data,
                            checklist: updatedChecklist,
                        },
                    });
                }
            });
    }
    onRemoveFile = (file) => {
        const { data } = this.state;
        this.setState({
            data: {
                ...data,
                checklist: data.checklist.filter(item => item.name !== file.name)
            }
        });
    }
    onRemoveCamoFile = (file) => {
        const { attachments } = this.state;
        this.setState({
            attachments: attachments.filter(item => item.file_name !== file.file_name)
        });
    }

    onLinkFile = (file, uuid, type) => {
        if (type == "link" || uuid) {
            const { data } = this.state;
            const updatedChecklist = data?.checklist ? [...data.checklist, file] : file;
            const folderUUID = data?.folder_uuid ? [...data?.folder_uuid, uuid] : uuid;

            let uuidData = [];
            let output = [];

            for (const arr of folderUUID) {
                uuidData = uuidData.concat(arr);
            }

            for (const arr of updatedChecklist) {
                output = output.concat(arr);
            }
            this.setState({
                data: {
                    ...data,
                    checklist: output,
                    folder_uuid: uuidData.join("")
                },
            });
        } else {
            this.onUpload(file)
        }

    }

    onSubmitFileLink = (id) => {
        const { data } = this.state;
        if (data?.checklist?.length > 0 && data.checklist.map(item => item.id).filter(item => item)) {
            let payload = {};
            if (data?.checklist.some(item => item.type === 'folder') && data?.checklist.some(item => item.type === 'file')) {
                payload.folder_ids = data?.checklist.filter(item => item.type === 'folder').map(file => file.id).join(',');
                payload.file_ids = data?.checklist.filter(item => item.type === 'file').map(file => file.id).join(',');
            } else if (data?.checklist.some(item => item.type === 'folder')) {
                payload.folder_ids = data?.checklist.filter(item => item.type === 'folder').map(file => file.id).join(',');
            } else {
                payload.file_ids = data?.checklist.filter(item => item.type === 'file').map(file => file.id).join(',');
            }
            globalPutService(`camo/ope_hist/${id}/document-checklists/`, payload)
                .then(response => {
                    if (checkApiStatus(response)) {
                        this.props.getResponseBack()
                        // this.props.props.enqueueSnackbar(response?.data?.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                    } else {
                        this.props.props.enqueueSnackbar(response?.data?.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                    }
                });
        }
        if (data?.folder_uuid?.length > 0 && data.folder_uuid) {
            let formData = new FormData();
            formData.append('folder_uuid', data.folder_uuid);
            for (let i = 0; i < data.checklist.filter(item => item instanceof File).length; i++) {
                formData.append('files[]', data.checklist.filter(item => item instanceof File).length && data.checklist.filter(item => item instanceof File)[i]);
            }
            globalPutService(`camo/ope_hist/${id}/document-checklists/`, formData)
                .then(response => {
                    if (checkApiStatus(response)) {
                        this.props.getResponseBack()
                    } else {
                        this.props.props.enqueueSnackbar(response?.data?.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                    }
                });
        }
    }

    removalHoursUsed = () => {
        const { data } = this.state
        if (data?.removal_engine_tsn?.length > 0 && (['NA', 'N/A', 'UNKNOWN', 'UNK']?.includes(data?.fitment_engine_tsn?.toUpperCase()))) {
            return ['NA', 'N/A', 'UNKNOWN', 'UNK']?.includes(data?.removal_engine_tsn?.toUpperCase()) ? 0 : data?.removal_engine_tsn
        }else if (data?.fitment_engine_tsn?.length > 0 && (['NA', 'N/A', 'UNKNOWN', 'UNK']?.includes(data?.removal_engine_tsn?.toUpperCase()))) {
            return 0
        } else if (parseFloat(data?.fitment_engine_tsn) > parseFloat(data?.removal_engine_tsn)) {
            return 0
        } else if (data?.removal_engine_tsn?.length > 0 && data?.fitment_engine_tsn?.length > 0) {
            return (data.removal_engine_tsn - data?.fitment_engine_tsn) >= 0 ? format_Commas((data?.removal_engine_tsn - data?.fitment_engine_tsn)?.toFixed(2)) : ''
        } else if (data?.removal_engine_tsn?.length > 0 && data?.fitment_engine_tsn === null) {
            return format_Commas(data.removal_engine_tsn)
        } else {
            return ''
        }
    }
    

    removalCycleUsed = () => {
        const { data } = this.state
        if (data?.removal_engine_csn?.length > 0 && (['NA', 'N/A', 'UNKNOWN', 'UNK']?.includes(data?.fitment_engine_csn?.toUpperCase()))) {
            return ['NA', 'N/A', 'UNKNOWN', 'UNK']?.includes(data?.removal_engine_csn?.toUpperCase()) ? 0 : data?.removal_engine_csn
        }else if (data?.fitment_engine_csn?.length > 0 && (['NA', 'N/A', 'UNKNOWN', 'UNK']?.includes(data?.removal_engine_csn?.toUpperCase()))) {
            return 0
        }  else if (parseInt(data?.fitment_engine_csn) > parseInt(data?.removal_engine_csn)) {
            return 0
        } else if (data?.removal_engine_csn && data?.fitment_engine_csn) {
            return (data.removal_engine_csn - data?.fitment_engine_csn) >= 0 ? format_Commas(data.removal_engine_csn - data?.fitment_engine_csn) : ''
        } else if (data?.removal_engine_csn?.length > 0 && data?.fitment_engine_csn === null) {
            return (data.removal_engine_csn)
        } else {
            return ''
        }
    }

    render() {
        const { data, error, attachments, operatorList, positionDropDownList, operatingThrust } = this.state
        const smbcInstance = getLocalStorageInfo()?.defaultLessor?.id === (config?.env?.key === "api" ? 465 : 532)
     
        return (
            <Fragment>
                <div id="AmpTaskForm">
                    <DialogTitle>
                        {this.props.formTitle}
                        <CloseIcon
                            onClick={() => { this.props.closeAddForm(); this.listResponseBack() }}
                            className="close-icon"
                        />
                    </DialogTitle>
                    <form
                        id="asset-form"
                        onSubmit={this.props.actionType === "add" ? (e) => this.handleSubmit(e) : (e) => this.updateData(e)}
                    >
                        <DialogContent>
                            <Grid spacing={2} container>
                                <Grid item xs={3}>
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <KeyboardDatePicker
                                            label="Fitment Date"
                                            id="fitment_date"
                                            format={dayMonthDateFormat}
                                            inputVariant="outlined"
                                            fullWidth
                                            margin="normal"
                                            disableFuture
                                            minDate={moment(this.props.basicDetails?.date_of_manufacture)}
                                            InputLabelProps={{ shrink: true }}
                                            value={data.fitment_date ? data.fitment_date : null}
                                            onChange={(data, value) => this.onFieldChange("fitment_date", moment(data).isValid() ? moment(data).format(backendDateFormat) : null)}
                                            error={error.fitment_date ? true : false}
                                            helperText={error.fitment_date ? error.fitment_date : ""}
                                            onPaste={(event) => event.preventDefault()}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid item xs={3}>
                                    <Autocomplete
                                        options={operatorList || []}
                                        getOptionLabel={(option) => option?.name}
                                        id="fitment_operator"
                                        value={data?.fitment_operator || null}
                                        onChange={(e, value) => this.onFieldChange("fitment_operator", value)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Fitment Operator"
                                                margin="normal"
                                                fullWidth
                                                InputLabelProps={{ shrink: true }}
                                                placeholder="Select Fitment Operator"
                                                variant="outlined"

                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        variant="outlined"
                                        label="Fitment A/C"
                                        id="fitment_aircraft"
                                        value={data.fitment_aircraft}
                                        onChange={(e) => this.onFieldChange('fitment_aircraft', e.target.value)}
                                        InputLabelProps={{ shrink: true }}
                                        inputProps={{ maxLength: 10 }}
                                        fullWidth
                                        margin="normal"
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        variant="outlined"
                                        label="Fitment Tail"
                                        id="fitment_tail"
                                        value={data.fitment_tail}
                                        onChange={(e) => this.onFieldChange('fitment_tail', e.target.value)}
                                        InputLabelProps={{ shrink: true }}
                                        inputProps={{ maxLength: 10 }}
                                        fullWidth
                                        margin="normal"
                                    />
                                </Grid>

                            </Grid>
                            <Grid spacing={1} container>
                                <Grid item xs={3}>
                                    <Autocomplete
                                        options={smbcInstance ? positionDropDownList.filter((item)=>[0,1,2].includes(item.id)) : positionDropDownList || []}
                                        getOptionLabel={(option) => option?.label}
                                        id="fitment_operator"
                                        value={data?.fitment_psn || ''}
                                        onChange={(e, value) => this.onFieldChange("fitment_psn", value)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Fitment PSN"
                                                margin="normal"
                                                fullWidth
                                                placeholder="Select Fitment PSN"
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}

                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <Autocomplete
                                        options={operatingThrust || []}
                                        getOptionLabel={(option) => option?.name}
                                        id="fitment_thrust"
                                        value={data?.fitment_thrust || null}
                                        onChange={(e, value) => this.onFieldChange("fitment_thrust", value)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Fitment Thrust"
                                                margin="normal"
                                                fullWidth
                                                placeholder="Select Fitment Thrust"
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}

                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        variant="outlined"
                                        label={
                                            <span style={{ verticalAlign: 'super' }}>
                                                Fitment A/F Hours
                                                <Tooltip placement='right' title={<span>Allow Keywords - NA, N/A,Unknown,UNK or 0 to 99999.99 </span>} arrow>
                                                    <InfoIcon fontSize='small' style={{ verticalAlign: 'middle', marginLeft: '2px', fontSize: 'small' }} />
                                                </Tooltip>
                                            </span>
                                        }
                                        id="fitment_aircraft_flying_hours"
                                        error={error.fitment_aircraft_flying_hours ? true : false}
                                        helperText={error.fitment_aircraft_flying_hours ? error.fitment_aircraft_flying_hours : ""}
                                        inputProps={{ maxLength: regexConstants.alphanumespcespclchar.test(data.fitment_aircraft_flying_hours) && 10 }}
                                        InputLabelProps={{ shrink: true, style: { pointerEvents: 'auto' } }}
                                        value={regexConstants.alphanumespcespclchar.test(data.fitment_aircraft_flying_hours) ? data.fitment_aircraft_flying_hours : format_Commas(data?.fitment_aircraft_flying_hours.replace(/[^0-9.]/g, ''))}
                                        onChange={(e) => {
                                            const value = format_Commas(e.target.value).replace(/[^0-9.]/g, '');
                                            const numericValue = value.replace(/[^\d.]/g, '');
                                            const dotCount = (numericValue.match(/\./g) || []).length;
                                            const isValid = dotCount ? (numericValue.replace(".", "").length - dotCount <= 6 && regexConstants.numberWithDot.test(numericValue)) : value.length <= 5 && regexConstants.numberWithDot.test(numericValue);
                                            if (regexConstants.alphanumespcespclchar.test(e.target.value)) {
                                                this.onFieldChange("fitment_aircraft_flying_hours", e.target.value);
                                                this.setState({ error: { ...error, fitment_aircraft_flying_hours: e.target.value === (null || undefined || "") ? "" : !specificKey.includes(e.target.value.toUpperCase()) && validationMess('Fitment Aircraft Flying Hours', true) }, })
                                            } else if (isValid || value === "") {
                                                this.onFieldChange("fitment_aircraft_flying_hours", numericValue);
                                                this.setState({ error: { ...error, fitment_aircraft_flying_hours: regexConstants.numberWithDot.test(numericValue) || e.target.value === (null || undefined || "") ? "" : e.target.value === null ? "" : "" } })
                                            }
                                        }}

                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        variant="outlined"
                                        label={
                                            <span style={{ verticalAlign: 'super' }}>
                                               Fitment A/F Cycle
                                                <Tooltip placement='right' title={<span>Allow Keywords - NA, N/A,Unknown,UNK or 0 to 99999 </span>} arrow>
                                                    <InfoIcon fontSize='small' style={{ verticalAlign: 'middle', marginLeft: '2px', fontSize: 'small' }} />
                                                </Tooltip>
                                            </span>
                                        }
                                        name="fitment_aircraft_flying_cycle"
                                        inputProps={{ maxLength: regexConstants.alphanumespcespclchar.test(data.fitment_aircraft_flying_cycle) && 9 }}
                                        error={error.fitment_aircraft_flying_cycle ? true : false}
                                        helperText={error.fitment_aircraft_flying_cycle ? error.fitment_aircraft_flying_cycle : ""}
                                        value={regexConstants.alphanumespcespclchar.test(data.fitment_aircraft_flying_cycle) ? data.fitment_aircraft_flying_cycle : format_Commas(data?.fitment_aircraft_flying_cycle.toString().replace(/[^0-9.]/g, ''))}
                                        onChange={(e) => {
                                            if (regexConstants.alphanumespcespclchar.test(e.target.value)) {
                                                this.onFieldChange("fitment_aircraft_flying_cycle", e.target.value);
                                                this.setState({ error: { ...error, fitment_aircraft_flying_cycle: e.target?.value === null || e.target?.value === "" ? "" : !specificKey.includes(e.target.value.toUpperCase()) && validationMess('Fitment AIrcraft Flying Cycle', false) }})

                                            } else {
                                                this.setState({ error: { ...error, fitment_aircraft_flying_cycle: e.target?.value === null || regexConstants.numberWithComma.test(e.target.value) || e.target?.value === "" ? "" : "" } })
                                                e.target.value.length <= 6 && (regexConstants.onlyNumeric.test(format_Commas(e.target.value).replace(/[^0-9.]/g, '')) || e.target.value === "") && this.onFieldChange('fitment_aircraft_flying_cycle', e.target.value, 'removeComma')
                                            }

                                        }
                                        }
                                        InputLabelProps={{ shrink: true, style: { pointerEvents: 'auto' } }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid spacing={1} container id="ht-dropdown">
                                <Grid item xs={3}>
                                    <TextField
                                        variant="outlined"
                                        label={
                                            <span style={{ verticalAlign: 'super' }}>
                                                Fitment Engine TSN
                                                <Tooltip placement='right' title={<span>Allow Keywords - NA, N/A,Unknown,UNK or 0 to 99999.99 </span>} arrow>
                                                    <InfoIcon fontSize='small' style={{ verticalAlign: 'middle', marginLeft: '2px', fontSize: 'small' }} />
                                                </Tooltip>
                                            </span>
                                        }
                                        id="fitment_engine_tsn"
                                        error={error.fitment_engine_tsn ? true : false}
                                        helperText={error.fitment_engine_tsn ? error.fitment_engine_tsn : ""}
                                        inputProps={{ maxLength: regexConstants.alphanumespcespclchar.test(data.fitment_engine_tsn) && 10 }}
                                        InputLabelProps={{ shrink: true, style: { pointerEvents: 'auto' } }}
                                        value={regexConstants.alphanumespcespclchar.test(data.fitment_engine_tsn) ? data.fitment_engine_tsn : format_Commas(data?.fitment_engine_tsn.replace(/[^0-9.]/g, ''))}
                                        onChange={(e) => {
                                            const value = format_Commas(e.target.value).replace(/[^0-9.]/g, '');
                                            const numericValue = value.replace(/[^\d.]/g, '');
                                            const dotCount = (numericValue.match(/\./g) || []).length;
                                            const isValid = dotCount ? (numericValue.replace(".", "").length - dotCount <= 6 && regexConstants.numberWithDot.test(numericValue)) : value.length <= 5 && regexConstants.numberWithDot.test(numericValue);
                                            if (regexConstants.alphanumespcespclchar.test(e.target.value)) {
                                                this.onFieldChange("fitment_engine_tsn", e.target.value);
                                                this.setState({ error: { ...error, fitment_engine_tsn: e.target.value === (null || undefined || "") ? "" : !specificKey.includes(e.target.value.toUpperCase()) && validationMess('Fitment Engine TSN', true) }, })
                                            } else if (isValid || value === "") {
                                                this.onFieldChange("fitment_engine_tsn", numericValue);
                                                this.setState({ error: { ...error, fitment_engine_tsn: regexConstants.numberWithDot.test(numericValue) || e.target.value === (null || undefined || "") ? "" : e.target.value === null ? "" : "" } })
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        variant="outlined"
                                        label={
                                            <span style={{ verticalAlign: 'super' }}>
                                                Fitment Engine CSN
                                                <Tooltip placement='right' title={<span>Allow Keywords - NA, N/A,Unknown,UNK or 0 to 99999</span>} arrow>
                                                    <InfoIcon fontSize='small' style={{ verticalAlign: 'middle', marginLeft: '2px', fontSize: 'small' }} />
                                                </Tooltip>
                                            </span>
                                        }
                                        name="fitment_engine_csn"
                                        id="fitment_engine_csn"
                                        inputProps={{ maxLength: regexConstants.alphanumespcespclchar.test(data.fitment_engine_csn) && 9 }}
                                        error={error.fitment_engine_csn ? true : false}
                                        helperText={error.fitment_engine_csn ? error.fitment_engine_csn : ""}
                                        value={regexConstants.alphanumespcespclchar.test(data.fitment_engine_csn) ? data.fitment_engine_csn : format_Commas(data?.fitment_engine_csn.toString().replace(/[^0-9.]/g, ''))}
                                        onChange={(e) => {
                                            if (regexConstants.alphanumespcespclchar.test(e.target.value)) {
                                                this.onFieldChange("fitment_engine_csn", e.target.value);
                                                this.setState({ error: { ...error, fitment_engine_csn: e.target?.value === null || e.target?.value === "" ? "" : !specificKey.includes(e.target.value.toUpperCase()) && validationMess('Fitment Engine CSN', false) }, })

                                            } else {
                                                this.setState({ error: { ...error, fitment_engine_csn: e.target?.value === null || regexConstants.numberWithComma.test(e.target.value) || e.target?.value === "" ? "" : "" } })
                                                e.target.value.length <= 6 && (regexConstants.onlyNumeric.test(format_Commas(e.target.value).replace(/[^0-9.]/g, '')) || e.target.value === "") && this.onFieldChange('fitment_engine_csn', e.target.value, 'removeComma')
                                            }

                                        }
                                        }
                                        InputLabelProps={{ shrink: true, style: { pointerEvents: 'auto' } }}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <KeyboardDatePicker
                                            label="Removal Date"
                                            id="removal_date"
                                            format={dayMonthDateFormat}
                                            inputVariant="outlined"
                                            fullWidth
                                            minDate={moment(this.props.basicDetails?.date_of_manufacture)}
                                            disableFuture
                                            InputLabelProps={{ shrink: true }}
                                            value={data.removal_date}
                                            onChange={(data, value) => this.onFieldChange("removal_date", moment(data).isValid() ? moment(data).format(backendDateFormat) : null)}
                                            error={error.removal_date ? true : false}
                                            helperText={error.removal_date ? error.removal_date : ""}
                                            onPaste={(event) => event.preventDefault()}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        variant="outlined"
                                        label={
                                            <span style={{ verticalAlign: 'super' }}>
                                                Removal A/F Hours
                                                <Tooltip placement='right' title={<span>Allow Keywords - NA, N/A,Unknown,UNK or 0 to 99999.99 </span>} arrow>
                                                    <InfoIcon fontSize='small' style={{ verticalAlign: 'middle', marginLeft: '2px', fontSize: 'small' }} />
                                                </Tooltip>
                                            </span>
                                        }
                                        id="removal_aircraft_flying_hours"
                                        error={error.removal_aircraft_flying_hours ? true : false}
                                        helperText={error.removal_aircraft_flying_hours ? error.removal_aircraft_flying_hours : ""}
                                        inputProps={{ maxLength: regexConstants.alphanumespcespclchar.test(data.removal_aircraft_flying_hours) && 10 }}
                                        InputLabelProps={{ shrink: true, style: { pointerEvents: 'auto' } }}
                                        value={regexConstants.alphanumespcespclchar.test(data.removal_aircraft_flying_hours) ? data.removal_aircraft_flying_hours : format_Commas(data?.removal_aircraft_flying_hours.replace(/[^0-9.]/g, ''))}
                                        onChange={(e) => {
                                            const value = format_Commas(e.target.value).replace(/[^0-9.]/g, '');
                                            const numericValue = value.replace(/[^\d.]/g, '');
                                            const dotCount = (numericValue.match(/\./g) || []).length;
                                            const isValid = dotCount ? (numericValue.replace(".", "").length - dotCount <= 6 && regexConstants.numberWithDot.test(numericValue)) : value.length <= 5 && regexConstants.numberWithDot.test(numericValue);
                                            if (regexConstants.alphanumespcespclchar.test(e.target.value)) {
                                                this.onFieldChange("removal_aircraft_flying_hours", e.target.value);
                                                this.setState({ error: { ...error, removal_aircraft_flying_hours: e.target.value === (null || undefined || "") ? "" : !specificKey.includes(e.target.value.toUpperCase()) && validationMess('Removal Aircraft Flying Hours', true) }})
                                            } else if (isValid || value === "") {
                                                this.onFieldChange("removal_aircraft_flying_hours", numericValue);
                                                this.setState({ error: { ...error, removal_aircraft_flying_hours: regexConstants.numberWithDot.test(numericValue) || e.target.value === (null || undefined || "") ? "" : e.target.value === null ? "" : "" } })
                                            }
                                        }}

                                    />
                                </Grid>

                            </Grid>
                            <Grid spacing={1} container>
                                <Grid item xs={3}>
                                    <TextField
                                        variant="outlined"
                                        id="removal_aircraft_flying_cycle"
                                        label={
                                            <span style={{ verticalAlign: 'super' }}>
                                               Removal A/F Cycle
                                                <Tooltip placement='right' title={<span>Allow Keywords - NA, N/A,Unknown,UNK or 0 to 99999 </span>} arrow>
                                                    <InfoIcon fontSize='small' style={{ verticalAlign: 'middle', marginLeft: '2px', fontSize: 'small' }} />
                                                </Tooltip>
                                            </span>
                                        }
                                        name="removal_aircraft_flying_cycle"
                                        inputProps={{ maxLength: regexConstants.alphanumespcespclchar.test(data.removal_aircraft_flying_cycle) && 9 }}
                                        error={error.removal_aircraft_flying_cycle ? true : false}
                                        helperText={error.removal_aircraft_flying_cycle ? error.removal_aircraft_flying_cycle : ""}
                                        value={regexConstants.alphanumespcespclchar.test(data.removal_aircraft_flying_cycle) ? data.removal_aircraft_flying_cycle : format_Commas(data?.removal_aircraft_flying_cycle.toString().replace(/[^0-9.]/g, ''))}
                                        onChange={(e) => {
                                            if (regexConstants.alphanumespcespclchar.test(e.target.value)) {
                                                this.onFieldChange("removal_aircraft_flying_cycle", e.target.value);
                                                this.setState({ error: { ...error, removal_aircraft_flying_cycle: e.target?.value === null || e.target?.value === "" ? "" : !specificKey.includes(e.target.value.toUpperCase()) && validationMess('Removal AIrcraft Flying Cycle', false) }})

                                            } else {
                                                this.setState({ error: { ...error, removal_aircraft_flying_cycle: e.target?.value === null || regexConstants.numberWithComma.test(e.target.value) || e.target?.value === "" ? "" : "" } })
                                                e.target.value.length <= 6 && (regexConstants.onlyNumeric.test(format_Commas(e.target.value).replace(/[^0-9.]/g, '')) || e.target.value === "") && this.onFieldChange('removal_aircraft_flying_cycle', e.target.value, 'removeComma')
                                            }
                                        }
                                        }
                                        InputLabelProps={{ shrink: true, style: { pointerEvents: 'auto' } }}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        variant="outlined"
                                        label={
                                            <span style={{ verticalAlign: 'super' }}>
                                                Removal Engine TSN
                                                <Tooltip placement='right' title={<span>Allow Keywords - NA, N/A,Unknown,UNK or 0 to 99999.99 </span>} arrow>
                                                    <InfoIcon fontSize='small' style={{ verticalAlign: 'middle', marginLeft: '2px', fontSize: 'small' }} />
                                                </Tooltip>
                                            </span>
                                        }
                                        id="removal_engine_tsn"
                                        error={error.removal_engine_tsn ? true : false}
                                        helperText={error.removal_engine_tsn ? error.removal_engine_tsn : ""}
                                        value={regexConstants.alphanumespcespclchar.test(data.removal_engine_tsn) ? data.removal_engine_tsn : format_Commas(data?.removal_engine_tsn.replace(/[^0-9.]/g, ''))}
                                        onChange={(e) => {
                                            const value = format_Commas(e.target.value).replace(/[^0-9.]/g, '');
                                            const numericValue = value.replace(/[^\d.]/g, '');
                                            const dotCount = (numericValue.match(/\./g) || []).length;
                                            const isValid = dotCount ? (numericValue.replace(".", "").length - dotCount <= 6 && regexConstants.numberWithDot.test(numericValue)) : value.length <= 5 && regexConstants.numberWithDot.test(numericValue);
                                            if (regexConstants.alphanumespcespclchar.test(e.target.value)) {
                                                this.onFieldChange("removal_engine_tsn", e.target.value);
                                                this.setState({ error: { ...error, removal_engine_tsn: e.target?.value === null || e.target?.value === "" ? "" : !specificKey.includes(e.target.value.toUpperCase()) && validationMess('Removal Engine TSN', true) }, })

                                            } else if (isValid || value === "") {
                                                this.onFieldChange("removal_engine_tsn", numericValue);
                                                this.setState({ error: { ...error, removal_engine_tsn: regexConstants.numberWithDot.test(numericValue) || e.target.value === (null || undefined || "") ? "" : e.target.value === null ? "" : "" } })

                                            }
                                        }}
                                        inputProps={{ maxLength: regexConstants.alphanumespcespclchar.test(data.removal_engine_tsn) && 10 }}
                                        InputLabelProps={{ shrink: true, style: { pointerEvents: 'auto' } }}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        variant="outlined"
                                        label={
                                            <span style={{ verticalAlign: 'super' }}>
                                                Removal Engine CSN
                                                <Tooltip placement='right' title={<span>Allow Keywords - NA, N/A,Unknown,UNK or 0 to 99999</span>} arrow>
                                                    <InfoIcon fontSize='small' style={{ verticalAlign: 'middle', marginLeft: '2px', fontSize: 'small' }} />
                                                </Tooltip>
                                            </span>
                                        }
                                        id="removal_engine_csn"
                                        error={error.removal_engine_csn ? true : false}
                                        inputProps={{ maxLength: regexConstants.alphanumespcespclchar.test(data.removal_engine_csn) && 9 }}
                                        helperText={error.removal_engine_csn ? error.removal_engine_csn : ""}
                                        value={regexConstants.alphanumespcespclchar.test(data.removal_engine_csn) ? data.removal_engine_csn : format_Commas(data?.removal_engine_csn.toString().replace(/[^0-9.]/g, ''))}
                                        onChange={(e) => {
                                            if (regexConstants.alphanumespcespclchar.test(e.target.value)) {
                                                this.onFieldChange("removal_engine_csn", e.target.value);
                                                this.setState({ error: { ...error, removal_engine_csn: e.target?.value === null || e.target?.value === "" ? "" : !specificKey.includes(e.target.value.toUpperCase()) && validationMess('Removal Engine CSN', false) }, })

                                            } else {
                                                this.setState({ error: { ...error, removal_engine_csn: e.target?.value === null || regexConstants.numberWithComma.test(e.target.value) || e.target?.value === "" ? "" : "" } })
                                                e.target.value.length <= 6 && (regexConstants.onlyNumeric.test(format_Commas(e.target.value).replace(/[^0-9.]/g, '')) || e.target.value === "") && this.onFieldChange('removal_engine_csn', e.target.value, 'removeComma')
                                            }
                                        }
                                        }
                                        InputLabelProps={{ shrink: true, style: { pointerEvents: 'auto' } }}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        variant="outlined"
                                        label={
                                            <span style={{ verticalAlign: 'super' }}>
                                                Removal TSLSV
                                                <Tooltip placement='right' title={<span>Allow Keywords - NA, N/A,Unknown,UNK or 0 to 99999.99 </span>} arrow>
                                                    <InfoIcon fontSize='small' style={{ verticalAlign: 'middle', marginLeft: '2px', fontSize: 'small' }} />
                                                </Tooltip>
                                            </span>
                                        }
                                        error={error.removal_tslsv ? true : false}
                                        helperText={error.removal_tslsv ? error.removal_tslsv : ""}
                                        inputProps={{ maxLength: regexConstants.alphanumespcespclchar.test(data.removal_tslsv) && 9 }}
                                        value={regexConstants.alphanumespcespclchar.test(data.removal_tslsv) ? data.removal_tslsv : format_Commas(data?.removal_tslsv.replace(/[^0-9.]/g, ''))}
                                        id="removal_tslsv"
                                        onChange={(e) => {
                                            const value = format_Commas(e.target.value).replace(/[^0-9.]/g, '');
                                            const numericValue = value.replace(/[^\d.]/g, '');
                                            const dotCount = (numericValue.match(/\./g) || []).length;
                                            const isValid = dotCount ? (numericValue.replace(".", "").length - dotCount <= 6 && regexConstants.numberWithDot.test(numericValue)) : value.length <= 5 && regexConstants.numberWithDot.test(numericValue);
                                            if (regexConstants.alphanumespcespclchar.test(e.target.value)) {
                                                this.onFieldChange("removal_tslsv", e.target.value);
                                                this.setState({ error: { ...error, removal_tslsv: e.target?.value === null || e.target?.value === "" ? "" : !specificKey.includes(e.target.value.toUpperCase()) && validationMess('Removal TSLSV', true) }, })

                                            } else if (isValid || value === "") {
                                                this.onFieldChange("removal_tslsv", numericValue);
                                                this.setState({ error: { ...error, removal_tslsv: regexConstants.numberWithDot.test(numericValue) || e.target.value === (null || undefined || "") ? "" : e.target.value === null ? "" : "" } })

                                            }
                                        }}
                                        InputLabelProps={{ shrink: true, style: { pointerEvents: 'auto' } }}
                                    />
                                </Grid>

                            </Grid>
                            <Grid spacing={1} container>
                                <Grid item xs={3}>
                                    <TextField
                                        variant="outlined"
                                        label={
                                            <span style={{ verticalAlign: 'super' }}>
                                                Removal CSLSV
                                                <Tooltip placement='right' title={<span>Allow Keywords - NA, N/A,Unknown,UNK or 0 to 99999</span>} arrow>
                                                    <InfoIcon fontSize='small' style={{ verticalAlign: 'middle', marginLeft: '2px', fontSize: 'small' }} />
                                                </Tooltip>
                                            </span>
                                        }
                                        id="removal_cslsv"
                                        error={error.removal_cslsv ? true : false}
                                        helperText={error.removal_cslsv ? error.removal_cslsv : ""}
                                        inputProps={{ maxLength: regexConstants.alphanumespcespclchar.test(data.removal_cslsv) && 9 }}
                                        value={regexConstants.alphanumespcespclchar.test(data.removal_cslsv) ? data.removal_cslsv : format_Commas(data?.removal_cslsv.toString().replace(/[^0-9.]/g, ''))}
                                        onChange={(e) => {
                                            if (regexConstants.alphanumespcespclchar.test(e.target.value)) {
                                                this.onFieldChange("removal_cslsv", e.target.value);
                                                this.setState({ error: { ...error, removal_cslsv: e.target?.value === null || e.target?.value === "" ? "" : !specificKey.includes(e.target.value.toUpperCase()) && validationMess('Removal CSLSV', false) }, })

                                            } else {
                                                this.setState({ error: { ...error, removal_cslsv: e.target?.value === null || regexConstants.numberWithComma.test(e.target.value) || e.target?.value === "" ? "" : "" } })
                                                e.target.value.length <= 6 && (regexConstants.onlyNumeric.test(format_Commas(e.target.value).replace(/[^0-9.]/g, '')) || e.target.value === "") && this.onFieldChange('removal_cslsv', e.target.value, 'removeComma')
                                            }
                                        }
                                        }
                                        InputLabelProps={{ shrink: true, style: { pointerEvents: 'auto' } }}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        variant="outlined"
                                        label="Removal Hours Used "
                                        id="removal_hours_used"
                                        value={this.removalHoursUsed()}
                                        disabled
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        variant="outlined"
                                        label="Removal Cylce Used "
                                        id="removal_cycle_used"
                                        value={this.removalCycleUsed()}
                                        disabled
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        variant="outlined"
                                        label="EGTM"
                                        id="removal_egtm"
                                        value={data.removal_egtm}
                                        onChange={(e, value) => this.onFieldChange("removal_egtm", e.target.value)}
                                        InputLabelProps={{ shrink: true }}
                                        inputProps={{ maxLength: 250 }}
                                    />
                                </Grid>

                            </Grid>
                            <Grid spacing={1} container>
                                <Grid item xs={3}>
                                    <TextField
                                        variant="outlined"
                                        label="Removal Event"
                                        id="removal_event"
                                        value={data.removal_event}
                                        onChange={(e, value) => this.onFieldChange("removal_event", e.target.value)}
                                        InputLabelProps={{ shrink: true }}
                                        inputProps={{ maxLength: 250 }}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        variant="outlined"
                                        label="Effective Work Carried Out"
                                        id="maint_effective_work_carried_out"
                                        value={data.maint_effective_work_carried_out}
                                        onChange={(e, value) => this.onFieldChange("maint_effective_work_carried_out", e.target.value)}
                                        InputLabelProps={{ shrink: true }}
                                        inputProps={{ maxLength: 250 }}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        variant="outlined"
                                        label="Maint Shop"
                                        id="maint_shop"
                                        value={data.maint_shop}
                                        onChange={(e, value) => this.onFieldChange("maint_shop", e.target.value)}
                                        InputLabelProps={{ shrink: true }}
                                        inputProps={{ maxLength: 250 }}
                                    />
                                </Grid>
                            </Grid>
                            <div className="border-grey"></div>
                            <Grid spacing={1} container>
                                <Grid item xs={6}>
                                    <TextField
                                        label="Remarks"
                                        margin='normal'
                                        variant='outlined'
                                        fullWidth
                                        value={data.remarks}
                                        InputLabelProps={{ shrink: true }}
                                        onChange={(e) => this.onFieldChange("remarks", e.target.value)}
                                        rows={3}
                                        multiline
                                        inputProps={{ maxLength: 250 }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <div className="upload-amp-textbox" style={{ border: "1px solid gray", textAlign: 'center' }}>
                                        <ul className="list-inline spacing-list">
                                            <li className="list-inline-item" onClick={this.handleOpenUploadFile} >
                                                <span className="flex-centered"><AttachmentIcon color='primary' fontSize='small' /> Upload File</span>
                                            </li>
                                            <li className="list-inline-item" onClick={this.handleOpenFile}>
                                                <span className="flex-centered"><LinkIcon color='primary' fontSize='small' />Link file from Records Module</span>
                                            </li>
                                        </ul>
                                    </div>
                                </Grid>
                                <Grid id="data-container" spacing={1} container>
                                    <Grid spacing={1} container>
                                        {this.props.actionType === 'add' ?
                                            <div className='checklist-files'>
                                                <ul className='list-inline'>
                                                    {data?.checklist?.map((file) =>
                                                        <li className='list-inline-item'>
                                                            <span className='file-name'>{file.name}</span>
                                                            <span className='file-remove'>
                                                                <a href={decodeURIComponent(file.path)} download={file.name}>  <GetAppIcon color="primary" /></a>
                                                                <CloseIcon onClick={() => this.onRemoveFile(file)} color='secondary' fontSize='small' />
                                                            </span>
                                                        </li>
                                                    )}
                                                    {attachments?.map((attachments) =>
                                                        <li className='list-inline-item'>
                                                            <span className='file-name'>{attachments.file_name}</span>
                                                            <span className='file-remove'>
                                                                <a href={decodeURIComponent(attachments.file)} download={attachments.file_name}>  <GetAppIcon color="primary" /></a>
                                                                <CloseIcon onClick={() => this.onRemoveCamoFile(attachments)} color='secondary' fontSize='small' />
                                                            </span>
                                                        </li>
                                                    )}
                                                </ul>
                                            </div>
                                            :
                                            <div className='checklist-files'>
                                                <ul className='list-inline'>
                                                    {data?.checklist?.files?.map((file) =>
                                                        <li className='list-inline-item'>
                                                            <span className='file-name'>{file.name}</span>
                                                            <span className='file-remove'>
                                                                <a href={decodeURIComponent(file.path)} download={file.name}>  <GetAppIcon color="primary" /></a>
                                                                <CloseIcon onClick={() => this.onUnlinkCheckListFile(data.checklist, file.id)} color='secondary' fontSize='small' />
                                                            </span>
                                                        </li>
                                                    )}
                                                    {(data.checklist?.folders?.length > 0) &&
                                                        data.checklist?.folders.map((file) => {
                                                            return (
                                                                <li className='list-inline-item' key={file.id}>
                                                                    <Tooltip title={`Records Data Room :- ${file?.location}`} arrow>
                                                                        <a href={`${config.domain.records}${assetType[this.props.props?.match.params.assetType]}/${file?.asset_slug}/data-room/technical/${file?.uuid}`} target="_new"><img style={{ width: '15px', float: 'right', marginRight: '-18px', marginTop: '7px' }} src={imgStoragePath + 'phase-2/RecordsIcon.svg'} alt="Reacords Data Room" /></a>
                                                                    </Tooltip>
                                                                    <span className='file-name'>{file.name}</span>
                                                                    <span className='file-remove'>
                                                                        <CloseIcon onClick={() => this.onUnlinkCheckListFile(data.checklist, file.id)} color='secondary' fontSize='small' />
                                                                    </span>
                                                                </li>
                                                            );
                                                        })}
                                                    {attachments?.map((attachments) =>
                                                        <li className='list-inline-item'>
                                                            <span className='file-name'>{attachments.file_name}</span>
                                                            <span className='file-remove'>
                                                                <a href={decodeURIComponent(attachments.file)} download={attachments.file_name}>  <GetAppIcon color="primary" /></a>
                                                                <CloseIcon onClick={() => this.deleteAttachmentFile(attachments.id, attachments)} color='secondary' fontSize='small' />
                                                            </span>
                                                        </li>
                                                    )}
                                                </ul>
                                            </div>}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions className="actions-btn">
                            <Button
                                type="button"
                                className="btn btn-primary"
                                onClick={this.props.actionType === "add" ? (e) => this.addFormData(e) : (e) => this.updateData(e)}
                            >
                                {this.props.actionType === "add" ? "Add" : "Update"}
                            </Button>

                            {this.props.actionType === "add" ? (<Button onClick={(e) => this.saveAndAddAnother(e)} type="button" className="btn btn-primary" > Save & Add Another </Button>
                            ) : (
                                ""
                            )}
                            <Button type="button" className="btn btn-primary" onClick={() => { this.props.closeAddForm(); this.listResponseBack() }} > Close </Button>
                        </DialogActions>
                        <Dialog open={this.state.linkFile} onClose={this.handleCloseFile} id="htDialog">
                            <LinkFiles
                                handleClose={() => this.handleCloseFile()}
                                uuid={this.props?.uuid?.uuid}
                                editFormId={this.props.editFormId}
                                getResponseBack={(file) => this.getResponseBack(file)}
                                listResponseBack={(file) => this.listResponseBack(file)}
                                actionType={this.props.actionType}
                                onLinkFile={(file, any, type) => this.onLinkFile(file, any, type)}
                                modType="ope_hist"
                                uploadFileInfo={data}
                                last_used_folder_uuid={this.props.last_used_folder_uuid_link_document}
                                attachments={attachments}
                                type="link"
                            />
                        </Dialog>
                        <Dialog open={this.state.uploadFile} onClose={this.handleUploadFileCloseFile} id="htDialog">
                            <UploadFile
                                handleClose={() => this.handleUploadFileCloseFile()}
                                uuid={this.props?.uuid?.uuid}
                                editFormId={this.props.editFormId}
                                getResponseBack={(file) => this.getResponseBack(file)}
                                listResponseBack={(file) => this.listResponseBack(file)}
                                actionType={this.props.actionType}
                                onLinkFile={(file, uuid) => this.onLinkFile(file, uuid)}
                                modType="ope_hist"
                                uploadFileInfo={data}
                                last_used_folder_uuid={this.props.last_used_folder_uuid}
                                onUpload={(file) => this.onUpload(file)}
                                type="upload"
                                checkbox_last_used_folder_uuid={this.props.checkbox_last_used_folder_uuid}
                                checkbox_last_used_folder_name={this.props.checkbox_last_used_folder_name}
                            />
                        </Dialog>
                    </form>
                </div>
            </Fragment>
        )
    }
}

export default OpTaskForm